import ServiceCard from "./ServiceCard";
import "../styles/ServiceCard.css";
import funnel from '../public/images/Group 58.png';
import webdesign from '../public/images/Frame 7.png';
import maintenance from '../public/images/Group 60.png';
import lead from '../public/images/Frame 7 (1).png';
import design from '../public/images/Group 62.png';
interface Card {
	title: string;
	src: string;
	text: string;
	bgColor: string;
}
const data: Card[] = [
	{
		src: "",
		title: "Strategy Consultancy",
		text: "We strategise with you to come up with the best solution, tailor-fit for your business needs.",
		bgColor: "light-blue",
	},
	{
		src: funnel,
		title: "Funnel Building",
		text: "We build sales funnels that will help sell your offers faster.",
		bgColor: "light-gold",
	},
	{
		src: webdesign,
		title: "Website Design",
		text: "We make use of trending styles to ensure that your business website stands out amongst rivals.",
		bgColor: "light-gold",
	},
	{
		src: maintenance,
		title: "Website maintenance",
		text: "Our agile team is committed to making sure your website is in good condition.",
		bgColor: "light-gold",
	},
	{
		src: lead,
		title: "Lead generation",
		text: "We effectively help generation not just leads, but potential customers to your business.",
		bgColor: "light-gold",
	},
	{
		src: design,
		title: "Product design",
		text: "We help design and implement your ideas in the most efficient and effective ways.",
		bgColor: "light-gold",
	},
];
const Services = () => {
	return (
		<>
			<section className="bg-radialgold services ">
				<h1 className="text-step-5  sm:text-step-3  pt-24 text-center sm:text-customblue text-black font-extrabold sm:font-bold">
					Our Services
				</h1>
				<div className="container-center mx-auto  grid-card">
					{data &&
						data.map((item,index) => {
							return (
								<div key={index}>
									<ServiceCard
										src={item.src}
										bgColor={item.bgColor}
										title={item.title}
                                        text={item.text}
									/>
								</div>
							);
						})}
				</div>
			</section>
		</>
	);
};

export default Services;
