import React from 'react';
interface ProjectCardProps{
    title:string;
    description:string;
    imageSrc:string
}
const ProjectCard:React.FC<ProjectCardProps> = ({title,description,imageSrc}) => {
    return ( <>
    <div className='border-b-lightgrey border-b-2 py-8'>
      <h3 className='text-step-2 sm:text-step--2 font-bold '>{title}</h3>
      <p className='text-step-0 sm:text-step--3 pt-4'>{description}</p>
      <img src={imageSrc} alt={title} className="pt-6" />
    </div>
    </> );
}
 
export default ProjectCard;