import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Hero from 'components/Hero';
import "./index.css"
import { Carousel } from 'flowbite-react';
import { Sidebar } from 'flowbite-react/lib/esm/components';
import Services from 'components/Services';
import Footer from 'components/Footer';
import OurStory from 'components/OurStory';
import Reviews from 'components/Reviews';
import Projects from 'components/Projects';
import SidebarNav from 'components/SideBar';
import { useState } from 'react';
import { SidebarProvider } from 'components/Context/SideBar';
function App() {
 
  return (
    <SidebarProvider>
     
    <div className="mx-auto  "   >
    
    <Navbar />
      <div className='main'>
     
      <Hero />
      <SidebarNav></SidebarNav>
      <Services/>
      <Projects />
      <OurStory />
      <Reviews />
      <Footer />
      </div>
      
    </div>
    </SidebarProvider>
  );
}

export default App;
 