import React, { createContext, useState } from "react";
import { SideBarContextType } from "./SIdebar.context";
type UserProviderProps = {
	children: React.ReactNode;
};
const SidebarContext = React.createContext<SideBarContextType | null>(null);
const UserDispatchContext = createContext<any>(undefined);
const SidebarProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [userDetails, setUserDetails] = useState<{ isOpen: Boolean }>({
		isOpen: false,
	});
	return (
		<>
			<SidebarContext.Provider value={userDetails}>
				<UserDispatchContext.Provider value={setUserDetails}>
					{children}
				</UserDispatchContext.Provider>
			</SidebarContext.Provider>
		</>
	);
};

export { SidebarProvider, SidebarContext, UserDispatchContext };
