import { useRef, useEffect, useContext,useState } from "react";
import { createPortal } from "react-dom";
import close from "../public/images/close.png";
import { Button2 } from "./Button";
import "../styles/SideBar.css";
import { UserDispatchContext, SidebarContext } from "./Context/SideBar";
import "../styles/Projects.css";
type SideBarProps = {
	isOpen: Boolean;
};
const SidebarNavigation = () => {
	const [isMobile, setIsMobile] = useState(false);

	const aboutRef = useRef<any>(null);
	const projectRef = useRef<any>(null);
	const reviewRef = useRef<any>(null);
	const bodyRef = useRef<any>(null);
	const sidebarRef = useRef<Boolean>(false);
	const userContext = useContext(SidebarContext);
	const setUserContext = useContext(UserDispatchContext);
	const toggleSidebar = () => {
		if(!sidebarRef.current){
			sidebarRef.current = true;
			setUserContext({ isOpen: !userContext?.isOpen });
		}else{
			setUserContext({ isOpen: !userContext?.isOpen });
		}
		
	};

	const handleBodyClick = (event: React.MouseEvent<HTMLDivElement>) => {
		console.log(event.currentTarget)
		if (bodyRef.current?.contains(event.target as Element)) {
			setUserContext({ isOpen: false });
		}
	};

	useEffect(() => {
		const isMobile: boolean =
			Math.min(window.screen.width, window.screen.height) < 768 ||
			navigator.userAgent.indexOf("Mobi") > -1;
		isMobile ? setIsMobile(true) : setIsMobile(false);
		const about = document.querySelector(".about");
		const project = document.querySelector(".work");
		const review = document.querySelector(".reviews");
		const bodyTag = document.querySelector(".main");
		bodyRef.current = bodyTag;
		aboutRef.current = about;
		projectRef.current = project;
		reviewRef.current = review;

		//document.body!.addEventListener("click", handleBodyClick);

		return () => {
			// document.body!.removeEventListener("click", function (e) {
			// 	handleBodyClick(e);
			// });
		};
	}, []);

	return (
		<>
			{ isMobile &&
				createPortal(
					<aside
						
					>
						<div className=" sidebar w-full fixed   z-50   h-full bg-black bg-opacity-50 "
						aria-label="Sidebar"
						onClick={toggleSidebar} style={{display:`${userContext!.isOpen?'block':'none'}`}}>

						</div>
						<div
							className={`${userContext!.isOpen?'fadeInRight':sidebarRef.current?'fadeOutRight':'hide'}  overflow-y-auto w-64 fixed z-50 sm:w-2/4    right-0  py-4 px-3 h-full bg-gray-50 rounded dark:bg-gray-800`}
							
							
						>
							<div
								className="flex items-end w-full justify-end pl-2.5 mb-5"
								onClick={toggleSidebar}
								
							> 
								<img
									src={close}
									className="mr-3 h-4 sm:h-7"
									alt="Flowbite Logo"
								/>
							</div>
							<ul className="pt-16 space-y-2 flex flex-col items-center justify-center">
								<li
									onClick={() => {
										(aboutRef.current as HTMLDivElement).scrollIntoView({
											behavior: "smooth",
										}); 
										setUserContext({ isOpen: false });
									}}
								>
									<a
										href="#!"
										className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
									>
										<span className="ml-3  text-step-2 text-grey">
											About us
										</span>
									</a>
								</li>

								<li
									onClick={() => {
										(projectRef.current as HTMLDivElement).scrollIntoView({
											behavior: "smooth",
										});
										setUserContext({ isOpen: false });
									}}
								>
									<a
										href="#!"
										className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
									>
										<span className="flex-1 ml-3 whitespace-nowrap text-step-2 text-grey">
											Projects
										</span>
									</a>
								</li>
								<li
									onClick={() => {
										(reviewRef.current as HTMLDivElement).scrollIntoView({
											behavior: "smooth",
										});
										setUserContext({ isOpen: false });
									}}
								>
									<a
										href="#!"
										className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
									>
										<span className="flex-1 ml-3 whitespace-nowrap text-step-2 text-grey">
											Testimonials
										</span>
									</a>
								</li>
							</ul>
							<div className="flex justify-center pt-24">
								<Button2 />
							</div>
						</div>
					</aside>,
					document.getElementById("sidebar-root") as HTMLBodyElement
				)}
		</>
	);
};

export default SidebarNavigation;
