import Typewriter from 'typewriter-effect';
import "../styles/Banner.css";
const Banner = () => {
    return (<>
       <section className="banner absolute   -bottom-10 bg-customblue rounded-md px-2 py-6 w-4/5  lg:w-3/4 mx-auto" style={{background:'#142945'}}>
        <div className='bg-customblue min-w-full' >
        <h3 className="text-darkgold text-center text-step--4 sm:text-step-0 font-extrabold ">
        <Typewriter
  options={{
    strings: ['Building and strengthening your online presence...'],
    autoStart: true,
    loop: true,
    wrapperClassName :'typewriter'
  }}
/>
            </h3> 
        </div>
       </section>
    </> 
    );
}
 
export default Banner; 