import React, { useEffect, useRef, useContext } from "react";
import logo from "../public/images/logo.png";
import { UserDispatchContext, SidebarContext } from "./Context/SideBar";

const Navbar: React.FC = () => {
	const userContext = useContext(SidebarContext);
	const setUserContext = useContext(UserDispatchContext);
	const aboutRef = useRef<any>(null);
	const projectRef = useRef<any>(null);
	const reviewRef = useRef<any>(null);

	useEffect(() => {
		const about = document.querySelector(".about");
		const project = document.querySelector(".work") as HTMLDivElement;
		const review = document.querySelector(".reviews");
		aboutRef.current = about;
		projectRef.current = project;
		reviewRef.current = review;
	}, []);

	const toggleSidebar = () => {
		setUserContext({ isOpen: !userContext?.isOpen });
	};

	return (
		<>
			<nav className=" fixed py-3 w-full z-10  bg-white border-gray-200 rounded bg-customwhit backdrop-blur-md shadow-md dark:bg-gray-800 dark:border-gray-700">
				<div className="container-center py-2 w-full  flex   justify-between ">
					<a href="#" className="flex items-center">
						<img
							src={logo}
							className="h-8  mr-3 sm:h-10"
							alt="Babskenky Logo"
						/>
					</a>
					<button
						data-collapse-toggle="navbar-solid-bg"
						type="button"
						className="flex items-end justify-end  text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
						aria-controls="navbar-solid-bg"
						aria-expanded="false"
					>
						<span className="sr-only">Open main menu</span>
						<svg
							className=" h-8"
							aria-hidden="true"
							fill={"var(--blue)"}
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => toggleSidebar()}
						>
							<path
								fill-rule="evenodd"
								d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
								clip-rule="evenodd"
							></path>
						</svg>
					</button>
					<div
						className="hidden w-full lg:block lg:w-auto"
						id="navbar-solid-bg"
					>
						<ul className="flex flex-col mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
							<li
								className="md:p-3"
								onClick={() => {
									(aboutRef.current as HTMLDivElement).scrollIntoView({
										behavior: "smooth",
									});
								}}
							>
								<a
									href="#!"
									className="block py-2 pl-3 pr-4 text-black text-step-0 sm:text-step--3  rounded md:bg-transparent md:text-black md:p-0 md:dark:text-white dark:bg-blue-600 md:dark:bg-transparent"
									aria-current="page"
								>
									About Us
								</a>
							</li>
							<li className="md:p-3">
								<a
									href="#!"
									className="block py-2 pl-3 pr-4 text-black text-step-0 sm:text-step--3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
									onClick={() => {
										(projectRef.current as HTMLDivElement).scrollIntoView({
											behavior: "smooth",
										});
									}}
								>
									<span>Projects</span>
								</a>
							</li>
							<li
								className="md:md:p-3"
								onClick={() => {
									(reviewRef.current as HTMLDivElement).scrollIntoView({
										behavior: "smooth",
									});
								}}
							>
								<a
									href="#!"
									className="block py-2 pl-3 pr-4 text-black text-step-0 sm:text-step--3 rounded  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
								>
									Testimonials
								</a>
							</li>
							<li className="md:border-blue-900 border-2 py-2 px-8 rounded-tr-lg rounded-bl-lg">
								<a
									href="mailto:hello@babskenky.com"
									className="block py-2 pl-3 pr-3 text-customblue text-step-0 sm:text-step--3 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent  md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
								>
									Let's Talk
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
